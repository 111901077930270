import React from "react"
import InformationSecurityPolicy from "../../../components/body/pages/en-ng/legal/information-security"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const InformationSecurityPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/information-security-policy"}
      title="Information Security Policy | Kuda | The Money App for Africans"
      description="This information security policy establishes a set of measures that must be uniformly applied across Kuda to ensure a secured operating environment."
    />
    <InformationSecurityPolicy />
  </Layout>
)

export default InformationSecurityPage
